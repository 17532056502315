<template>
  <div class="minelists-style">
    <titles title="" />
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist"
      >
        <el-card class="box-card" v-for="item in list" :key="item.id">
          <div class="between-center">
            <img
              v-if="item.status == 1"
              :src="item.image"
              alt=""
              class="item-i1"
            />
            <img
              v-else-if="item.status == 0"
              :src="language == '1' ? $concatImg('img60') : $concatImg('img61')"
              alt=""
              class="item-i1"
            />
            <img
              v-else-if="item.status == 2"
              :src="language == '1' ? $concatImg('img62') : $concatImg('img63')"
              alt=""
              class="item-i1"
            />
            <div class="item-t1 break">{{item.name}}#{{ item.tokenid }}</div>
            <div class="item-t2" @click="goAhead(`/newdetail?id=${item.nft_id}&isup=1&chainType=${item.chain}`)">{{$t('text.t77')}}</div>
          </div>
        </el-card>
      </van-list>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import titles from "@/components/titles/index.vue";
export default {
  components: {
    titles,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
      address: "",
    };
  },
  computed: {
    ...mapState(["language"]),
  },
  created() {
    this.address = this.$route.query.addr;
  },
  mounted() {},
  methods: {
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
        contract: this.address,
      };
      this.$http.get("/collection/mineLists", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
  },
};
</script>

<style lang="less">
.minelists-style {
  .list {
    padding: 21px 0 0;
    .box-card {
      width: 300px;
      border-radius: 10px;
      margin: 0 auto 20px;
      .item-i1 {
        width: 33px;
        height: 33px;
        margin-right: 10px;
      }
      .item-t1 {
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        flex: 1;
      }
      .item-t2 {
        font-size: 12px;
        font-weight: 500;
        color: #1359b0;
        margin-left: 10px;
      }
    }
  }
}
</style>